// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-color: #282828;
    background-size: cover;
    background-position: center;

    .account-decor {
        width: 22vw;
        height: 22vw;
        position: fixed;
        border-radius: 50%;
    }

    .account-decor-1 {
        top: 0;
        right: 0;
        box-shadow: -3px 3px 6px -2px #1a8d7f;
    }

    .account-decor-2 {
        bottom: 0;
        left: 0;
        box-shadow: 3px -3px 6px -2px #766827;
    }

    .account-decor-3 {
        bottom: 0;
        right: 0;
        border-radius: 10%;
        box-shadow: -3px -3px 6px -2px #2d457c;
    }
}

.account-login-box {
    background-color: #2c2c2c;
    border-radius: 1rem;

    .form-label {
        color: #fff;
    }

    .form-control,
    .input-group-password {
        background-color: transparent;
        color: #fff;
        border: 1px solid #393939;
        border-radius: 0.5rem;
    }

    .btn {
        border-radius: 0.5rem;
    }
}

// Logout page
.logout-icon {
    width: 140px;
}

// Authentication fluid 
.auth-fluid {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
    background: $auth-img-bg center;
    background-size: cover;

    .auth-fluid-form-box {
        max-width: 480px;
        border-radius: 0;
        z-index: 2;
        padding: 3rem 2rem;
        background-color: $auth-bg;
        position: relative;
        width: 100%;
    }

    .auth-fluid-right {
        padding: 6rem 3rem;
        flex: 1;
        position: relative;
        color: $white;
        background-color: rgba($black, 0.3);
    }
}

.auth-brand {
    margin-bottom: 2rem;
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;

    p.lead {
        font-size: 1.125rem;
        margin: 0 auto 20px auto;
        max-width: 700px;
    }
}

@include media-breakpoint-up(lg) {
    .auth-brand {
        position: absolute;
        top: 3rem;
    }
}

@include media-breakpoint-down(lg) {
    .auth-fluid {
        display: block;

        .auth-fluid-form-box {
            max-width: 100%;
            min-height: 100vh
        }

        .auth-fluid-right {
            display: none;
        }
    }
}

.auth-logo {
    .logo-light {
        display: $logo-auth-light-display;
    }

    .logo-dark {
        display: $logo-auth-dark-display;
    }
}
