// 
// accordions.scss
//

.custom-accordion {
    .accordion-arrow {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
    }

    a {
        &.collapsed {
            i.accordion-arrow {
                &:before {
                    content: "\F0142";
                }
            }
        }
    }
}

.accordion-header.workspace-accordion .accordion-button {
    padding: 0 0.25rem;

    &:after {
        margin-left: 1rem;
    }

    &:focus {
        border: 0 none;
        box-shadow: none;
    }

    &:not(.collapsed) {
        background-color: transparent;
    }
}
