// 
// modal.scss
//
.modal-content {
    border-radius: 1rem;
    box-shadow: $box-shadow-avora;
}

.modal-body {

    .card {
        box-shadow: none;
        border-bottom: 1px solid $gray-300;
        border-radius: 0;

        &:last-of-type {
            border-bottom: 0;
        }

        &.avora-modal-card {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            margin-left: -1rem;
            margin-right: -1rem;
            margin-top: -1rem;
        }

    }
}

// Title text within header
.modal-title {
    margin-top: 0;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%, 0) !important;

    button.btn-close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {

        .modal-right,
        .modal-left {
            transform: translate(0, 0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .btn-close {
        color: $white  !important;
    }
}
