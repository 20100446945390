// 
// kanbanboard.scss
//

#kanbanboard {
    height: calc(100% - 130px);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;

    overflow-x: auto;
    overflow-y: hidden;

    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    .listWrapper {
        height: 100%;
        max-height: 100%;
        white-space: nowrap;

        .avora-crm-list {
            height: calc(100% - 90px);
            padding: 1.5rem 1rem .25rem;

            .avora-add-new {
                padding: .45rem 0rem;
                color: $gray-900;
                font-size: 1rem;
                font-weight: bold;
            }

            .avora-task-title {
                font-size: 1rem;
            }

            .avora-top-button {
                padding: 0rem 0.25rem;
                background: #fff;
                border: 1px solid $gray-300;
                border-radius: 0.5rem;
            }

            .header-title {
                font-size: 1.25rem;
                font-weight: bold;
            }
        }

        .listContent {
            display: flex;
            flex-direction: column;
            // height: 100%;
            max-height: 100%;
            position: relative;
            white-space: normal;
            width: 100% !important;
            //overflow-y: auto;
        }
    }

    &::-webkit-scrollbar {
        width: 0.45rem;
        height: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgb(207, 207, 207);
        border-radius: 25px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar {
        width: 0.45rem;
        height: 0.5rem;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgb(207, 207, 207);
        border-radius: 25px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }
}

.subtask {
    display: flex;
    align-items: center;
}

.listHeader {
    flex: 0 0 auto;
    min-height: 33px;
}

.listCards {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
}

.listFooter {
    max-height: 40px;
}

.tasklist {
    min-height: 40px;
    margin-bottom: 0;
    padding-right: 10px;
    padding-bottom: 10px;

    li {
        background-color: lighten($card-bg, 4%);
        border: 0 none;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 1rem;
        box-shadow: $box-shadow-avora;

        &:last-of-type {
            margin-bottom: 0;
        }

        .btn-sm {
            padding: 2px 8px;
            font-size: 12px;
        }
    }

    li.removed {
        border: 1px solid #f1556c !important;
        color: #f1556c !important;
    }

    .form-check {
        margin-left: 20px;
        margin-top: 5px;

        .form-check-input {
            margin-left: 0;
        }
    }
}

.task-placeholder {
    border: 1px dashed $gray-300  !important;
    background-color: $gray-100  !important;
    padding: 20px;
}

.avora-label {
    margin-right: 0.25rem;
    padding: 0.25rem;
}
