//
// dropzone.scss
//

.dropzone {
    border: 2px dashed $input-border-color;
    background: $input-bg;
    border-radius: 6px;
    cursor: pointer;
    min-height: 100px;
    padding: 0.5rem;
    box-shadow: $components-shadow-sm;

    .dz-message {
        text-align: center;
        margin: 0;
        outline: none;
    }

    &.dz-started {
        .dz-message {
            display: none;
        }
    }
}
