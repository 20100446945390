//
// topbar.scss
//

// Logo
.logo {
    display: block;

    .logo-lg-text-dark {
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }

    .logo-lg-text-light {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 22px;
        text-transform: uppercase;
    }
}

.logo-box {
    height: $topbar-height;
    width: $leftbar-width;
    float: left;
    transition: all 0.1s ease-out;

    .logo {
        line-height: $topbar-height;

        img {
            max-width: 100%;
        }
    }
}

.navbar-custom {
    padding: 0 10px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    height: $topbar-height;
    z-index: 1001;
    background-color: $bg-topbar-light !important;
    box-shadow: $box-shadow-sm;

    .topnav-menu {
        >li {
            float: left;
        }

        .nav-link {
            padding: 0 15px;
            min-width: 32px;
            display: block;
            line-height: $topbar-height;
            text-align: center;
            max-height: $topbar-height;
            color: $gray-700;
        }
    }

    .dropdown {
        .nav-link.show {
            background-color: rgba($dark, 0.03);
        }
    }

    .container-fluid {
        padding: 0;
    }

    /* Search */
    .app-search {
        height: $topbar-height;
        display: grid;
        
        width: 400px;
        @media (max-width: 992px) {
            width: 100vw;
        }

        margin-right: 20px;
        align-items: center;

        // .app-search-box {
        display: table-cell;
        vertical-align: middle;
        position: relative;

        .react-select__placeholder,
        input::placeholder {
            font-size: 0.8125rem;
            margin: 0;
            color: $gray-500 !important;
        }

        // }

        .react-select__menu {
            overflow: hidden;
            
            width: 640px !important;
            @media (max-width: 992px) {
                width: 100% !important;
            }

            margin-top: 0;
        }

        .react-select__input,
        .react-select__value-container {
            padding: 0;
            color: $dark !important;
        }

        .form-control,
        .react-select__control {
            border: none;
            height: 38px;
            padding-left: 20px;
            padding-right: 0;
            box-shadow: none;
            border-radius: 30px 30px 30px 30px;
            color: $dark;
            background-color: $gray-100;
            border-color: $gray-100;
        }

        .input-group-text {
            margin-left: 0 !important;
            z-index: 4;
        }

        .btn {
            // background-color: rgba($white, 0.07);
            border-color: transparent;
            border-radius: 0 30px 30px 0;
            box-shadow: none !important;
            background-color: $gray-100;
            color: $gray-400;
        }
    }

    .button-menu-mobile {
        border: none;
        display: inline-block;
        height: $topbar-height;
        line-height: $topbar-height;
        width: 60px;
        background-color: transparent;
        font-size: 24px;
        cursor: pointer;
        color: $dark;
    }

    .button-menu-mobile.disable-btn {
        display: none;
    }
}

/* Notification */
.noti-scroll {
    max-height: 230px;
}

.notification-list {
    margin-left: 0;

    .noti-title {
        background-color: transparent;
        padding: 15px 20px;
    }

    .noti-icon-badge {
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 10px;
    }

    .notify-item {
        padding: 12px 20px;

        .font-weight-bold {
            font-weight: bold;
        }

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            color: $white;
        }

        .notify-details {
            margin-bottom: 5px;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $gray-800;

            b {
                font-weight: 500;
            }

            small {
                display: block;
            }

            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }
        }

        .user-msg {
            margin-left: 45px;
            white-space: normal;
            line-height: 16px;
        }
    }

    .profile-dropdown {
        .notify-item {
            padding: 7px 20px;
        }
    }
}

.noti-icon {
    font-size: 21px;
    vertical-align: middle;
}

.profile-dropdown {
    min-width: 170px;

    i {
        vertical-align: middle;
        margin-right: 5px;
    }
}

.nav-user {
    padding: 0 12px !important;

    img {
        height: 32px;
        width: 32px;
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle='fullscreen'] {
        .fe-maximize::before {
            content: '\e88d';
        }
    }
}

@include media-breakpoint-down(lg) {
    .logo-box {
        width: $leftbar-width-condensed !important;
        padding-right: 0 !important;
    }
}

@media (max-width: 600px) {
    .navbar-custom {
        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    body[data-layout-mode='horizontal'] {

        // Navbar Button - Horizontal
        .navbar-toggle {
            border: 0;
            position: relative;
            padding: 0;
            margin: 0;
            cursor: pointer;

            .lines {
                width: 25px;
                display: block;
                position: relative;
                height: 16px;
                transition: all 0.5s ease;
                margin-top: calc(#{$topbar-height - 16px} / 2);
            }

            span {
                height: 2px;
                width: 100%;
                background-color: $gray-700;
                display: block;
                margin-bottom: 5px;
                transition: transform 0.5s ease;
            }

            &.open {
                span {
                    position: absolute;

                    &:first-child {
                        top: 7px;
                        transform: rotate(45deg);
                    }

                    &:nth-child(2) {
                        visibility: hidden;
                    }

                    &:last-child {
                        width: 100%;
                        top: 7px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

body[data-layout-mode='horizontal'] {
    .button-menu-mobile {
        display: none;
    }

    .logo-box {
        width: auto;
        padding-right: 50px;
        background-color: transparent;
    }

    @media (min-width: 992px) and (max-width: 1366px) {
        .logo-box {
            padding-left: 20px;
        }
    }
}

@media (max-width: 360px) {
    .navbar-custom {
        .topnav-menu {
            .nav-link {
                padding: 0 12px;
            }
        }

        .button-menu-mobile {
            width: 45px;
        }
    }
}
