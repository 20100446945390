//
// forms.scss
//

.form-control,
.form-select {
    box-shadow: $components-shadow-sm;
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }

    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box 
.search-bar {

    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 9;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather !important;
        content: "\e86a";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\e86e";
        }
    }
}

// Custom matatás
$main-color: #4fcb8d;
$heading-color: #292d32;
$transition: all ease .5s;

.form-input,
.form-input.form-control,
.form-typeahead .rbt-input {
    height: 50px;
    min-height: 50px !important;
    color: $heading-color !important;
	border: 1px solid #F1F5FF !important;
	background-color: #F1F5FF !important;
	border-radius: 10px !important;
	font-size: 14px !important;
	padding: 10px 20px !important;
	width: 100% !important;
	transition: $transition !important;
	&::placeholder {
		color: #67748E !important;
		font-size: 13px !important;
	}
	&:focus {
		color: $heading-color !important;
		background-color: transparent !important;
		box-shadow: unset !important;
		outline: 0 !important;
		border-color: $main-color !important;
	}
}
.form-input:hover, .form-input {
    &:focus {
        box-shadow: unset;
    }
}
textarea {   
    &.form-input {
        height: auto !important;
    }
}

.input-field,
.input-typeahead .rbt-input,
.avora-custom-table .form-control,
.avora-custom-table .form-select,
#chat-form .form-control {
    height: 38px;
    padding-left: 20px;
    padding-right: 0;
    box-shadow: none;
    border-radius: 30px 30px 30px 30px;
    color: #323a46;
    background-color: #eef4f7;
    border-color: #f3f7f9;
    border: 1px solid #ced4da;
    min-width: 80px;
}

.avora-rounded .form-control,
.avora-rounded button {
    border-radius: 100px;
}

// Typeahead
.rbt-aux {
    button {
        border: 0 !important;
        background-color: transparent;
    }
}