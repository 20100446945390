// 
// backgrounds.scss
//


@each $color,
$value in $theme-colors {
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }

    .bg-hover-#{$color}:hover {
        background-color: rgba(($value), 1) !important;
    }

    .bg-hover-soft-#{$color}:hover {
        background-color: rgba(($value), 0.25) !important;
    }
}

.bg-ghost {
    opacity: 0.4;
}
